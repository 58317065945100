import React from 'react'
import Table from '../Table'
import { useTranslation } from 'react-i18next'

const IG32Table = ({ regions }) => {
  const { t } = useTranslation()
  //const yearsWithData = []
  // const wrangleData = () => {
  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       //gets the years that have data so we know what columns to make
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key)) {
  //         const year = +key.slice(-4)
  //         if (value) {
  //           yearsWithData.push(year)
  //           return { year, value }
  //         } else return null
  //       } else return null
  //     })
  //     .filter((item) => item)
  const BASELINE_YEAR = 2019
  const years = []
  const ig32Data = regions.reduce((acc, d) => {
    // years.push(d.YEAR)
    // acc[d.YEAR] = d.VALUE_NUM.toFixed()
    if (d.YEAR !== BASELINE_YEAR) years.push(d.YEAR)
    acc[d.YEAR === BASELINE_YEAR ? 'Baseline' : d.YEAR] = d.VALUE_NUM.toFixed()
    acc['label'] = t('uhc index')
    return acc
  }, {})

  //   const completeFormattedData = [
  //     Object.assign(formattedData, {
  //       label: t('uhc index')
  //     })
  //   ]

  //   return completeFormattedData
  // }
  // const ig32Data = wrangleData()
  //console.log('ig32data', ig32Data)
  const data = React.useMemo(() => [ig32Data], [ig32Data])
  const yearHeaders = years?.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: '',
        accessor: 'label' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]

    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  return (
    <Table
      data={data}
      columns={columns}
      description={t('A table that shows UHC Index for each year.')}
    />
  )
}

export default IG32Table
